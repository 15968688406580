<template>
  <section class="grid" :class="[columnsClass, gapClass]"><slot/></section>
</template>

<script>
export default {

  props: {
    columns: {
      type: [Number, String],
      default: 1,
    },

    gap: {
      type: [Number, String],
      default: 1,
    }
  },

  computed: {
    columnsClass() {
      return `has-${this.columns}-columns`
    },

    gapClass() {
      return `gap-${this.gap}`
    }
  }

}
</script>
